import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Block, Grid } from "@sonos-inc/web-ui-core"
import Layout from "../components/Layout"
import BlogpostSummary from "../components/BlogpostSummary"
import Pagination, { postsPerPage } from "../components/Pagination"
import SearchBar from "../components/SearchBar"
import { defaultGridTemplateColumns } from "../constants/layout"
import { H3 } from "../components/typography"
import { isBrowser } from "../utils/isBrowser"
import { useDebounce, useDidUpdateEffect } from "../hooks"

export default function Search({ data }) {
  const location = useLocation()
  const [showFilters, setShowFilters] = useState(true)
  const [isDesktop, setIsDesktop] = useState(
    isBrowser && window.innerWidth > 1024
  )

  const searchParams = new URLSearchParams(location.search)
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 0)
  const [searchText, setSearchText] = useState(searchParams.get("text") || "")
  const debouncedSearchText = useDebounce(searchText, 100)

  //avoid first rendering as componentDidMount
  useDidUpdateEffect(() => {
    if (!isBrowser) return
    const url = new URL(window.location.href)
    url.searchParams.set("page", 1)
    setCurrentPage(1)
    if (debouncedSearchText) {
      url.searchParams.set("text", debouncedSearchText)
    } else {
      url.searchParams.delete("text")
    }
    window.history.replaceState("", "", url)

    // hide filters for small screen
    if (!isDesktop) {
      setShowFilters(false)
    }
  }, [isDesktop, debouncedSearchText])

  function pageChange(i) {
    if (!isBrowser) return
    const url = new URL(window.location.href)
    url.searchParams.set("page", i + 1)
    window.history.replaceState("", "", url)
    setCurrentPage(i + 1)
  }

  const { allSanityPost } = data

  const matchedPosts = allSanityPost.nodes.filter((post) => {
    if (!debouncedSearchText) return false
    const lowerCaseText = debouncedSearchText.toLowerCase()
    if (post.title.toLowerCase().includes(lowerCaseText)) return true
    if (
      post.authors.some(({ name }) =>
        name.toLowerCase().includes(lowerCaseText)
      )
    )
      return true
    if (
      post.categories.some(({ title }) =>
        title.toLowerCase().includes(lowerCaseText)
      )
    )
      return true
    else return false
  })

  useEffect(() => {
    if (matchedPosts.length === 0) {
      if (!isBrowser) return
      const url = new URL(window.location.href)
      url.searchParams.delete("page")
      window.history.replaceState("", "", url)
      setCurrentPage(0)
    }
  }, [matchedPosts])

  const numPages = Math.ceil(matchedPosts.length / postsPerPage)
  const postsInPage = matchedPosts.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  )

  return (
    <Layout>
      <SearchBar
        allSanityPost={allSanityPost}
        searchText={searchText}
        setSearchText={setSearchText}
        isDesktop={isDesktop}
        setIsDesktop={setIsDesktop}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        isNoResult={debouncedSearchText && matchedPosts.length === 0}
      />
      <Grid gridTemplateColumns={defaultGridTemplateColumns}>
        {matchedPosts.length ? (
          <H3
            mb={["big", null, null, "48px"]}
            fontSize={["16px", null, null, "21px"]}
            gridColumn={["3/26", null, "3/23", "6/25"]}
          >
            {matchedPosts.length}{" "}
            {matchedPosts.length === 1 ? "result" : "results"}
          </H3>
        ) : null}
      </Grid>
      <Block>
        {postsInPage.map((post) => (
          <BlogpostSummary key={post?.id} Element="li" {...post} />
        ))}
      </Block>
      <Pagination
        currentPage={Number(currentPage)}
        numPages={numPages}
        customPageChangeHandler={pageChange}
      />
    </Layout>
  )
}

export const searchPageData = graphql`
  query {
    allSanityPost(
      filter: {
        slug: { current: { ne: null } }
        isFuture: { eq: false }
        publishedAt: { ne: null }
      }
      sort: { fields: [publishedAt], order: [DESC] }
    ) {
      nodes {
        ...PostDataFragment
        authors {
          name
        }
      }
    }
  }
`
