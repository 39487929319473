import React, { useState, useRef, useEffect } from "react"
import {
  Block,
  Grid,
  styled,
  IconBack,
  Flex,
  IconArrowBoldRight,
  IconX,
} from "@sonos-inc/web-ui-core"
import Link from "../components/Link"
import { defaultGridTemplateColumns } from "../constants/layout"
import { isBrowser } from "../utils/isBrowser"

const InputBlock = styled(Block.withComponent("input"))({
  border: 0,
  borderBottom: "1px solid #979797",
  outline: 0,
  background: "transparent",
  display: "block",
  width: "100%",
  paddingBottom: "0.5rem",
  lineHeight: "26px",
  fontWeight: 500,
  "&:focus": {
    borderBottom: "1px solid #000000",
  },
  "::placeholder": {
    color: "#D8D8D8",
  },
  "@media (min-width: 64rem)": {
    lineHeight: "30px",
  },
})

const ResetButton = styled(Block.withComponent("button"))({
  position: "absolute",
  right: "0",
  top: "0",
  lineHeight: "26px",
  background: "none",
  border: "none",
  color: "#242424",
  "&:hover": {
    cursor: "pointer",
  },
  "@media (min-width: 64rem)": {
    lineHeight: "30px",
  },
})

const ResetIcon = styled(IconX)({
  verticalAlign: "middle",
})

// there will be a font weight bug of mac if button used here
const FilterTrigger = styled(Flex)({
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "21px",
  alignItems: "baseline",
  background: "none",
  border: "none",
  "@media (min-width: 64rem)": {
    fontSize: "12px",
  },
})

const CenterFlex = styled(Flex)({
  alignItems: "center",
  gap: "8px",
})

const FilterTriggerContent = styled(CenterFlex)({
  "&:hover": {
    cursor: "pointer",
  },
  "@media (min-width: 64rem)": {
    flexDirection: "row-reverse",
  },
})

const NoResultBlock = styled(Block.withComponent("p"))({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#868686",
})

const LinkContainerBlock = styled(Block)({
  "@media (min-width: 52rem)": {
    flex: "1 0 50%",
  },
  "@media (min-width: 64rem)": {
    flex: "0 0 33%",
  },
})

export default function SearchBar({
  allSanityPost,
  searchText,
  setSearchText,
  isDesktop,
  setIsDesktop,
  showFilters,
  setShowFilters,
  isNoResult,
}) {
  const [maxHeight, setMaxHeight] = useState("initial")

  const categoriesFromAllPosts = allSanityPost.nodes
    .flatMap(({ categories }) => categories)
    .map(({ title }) => title)
  const distinctCategories = [...new Set(categoriesFromAllPosts)].sort((a, b) =>
    a.localeCompare(b)
  )
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const animateRef = useRef(null)

  useEffect(() => {
    // remember the initial height of current element when filter expands
    if (animateRef?.current && showFilters) {
      setMaxHeight(animateRef.current.scrollHeight)
    }
  }, [showFilters])

  useEffect(() => {
    function handleResize() {
      // forget the previous initial height value
      setMaxHeight("initial")
      setIsDesktop(isBrowser && window.innerWidth > 1024)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [setIsDesktop])

  function handlePress(event) {
    if (event.key === "Enter") {
      inputRef.current.blur()
    }
  }

  function toggleFilter() {
    setShowFilters(!showFilters)
  }

  function resetSearch() {
    setSearchText("")
  }

  return (
    <>
      <Grid
        gridTemplateColumns={defaultGridTemplateColumns}
        mb={["32px", null, null, "64px"]}
      >
        <Block
          position="relative"
          gridColumn={["3/26", null, "3/23", "6/25"]}
          mb={[
            isNoResult ? "tiny" : "large",
            null,
            null,
            isNoResult ? "small" : "medium",
          ]}
        >
          <InputBlock
            type="text"
            onKeyPress={handlePress}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            value={searchText}
            placeholder={
              isDesktop ? "Search for titles, authors and categories" : "Search"
            }
            fontSize={["22px", null, null, "26px"]}
            ref={inputRef}
          />
          {searchText ? (
            <ResetButton fontSize={["16px"]} onClick={resetSearch}>
              <ResetIcon />
            </ResetButton>
          ) : null}
        </Block>

        {isNoResult ? (
          <NoResultBlock
            gridColumn={["3/26", null, "3/23", "6/25"]}
            mb={["medium", null, null, "large"]}
          >
            No results could be found for this search.
          </NoResultBlock>
        ) : null}

        <FilterTrigger gridColumn={["3/26", null, "3/23", "6/10"]}>
          <FilterTriggerContent
            onClick={toggleFilter}
            onKeyPress={toggleFilter}
            role="button"
            tabIndex="0"
          >
            Filter by Category
            <IconBack
              fontSize="12px"
              style={{
                transition: "0.26s all ease",
                transform: isDesktop
                  ? showFilters
                    ? "rotate(-90deg)"
                    : "rotate(-180deg)"
                  : showFilters
                  ? "rotate(90deg)"
                  : "rotate(-90deg)",
              }}
            />
          </FilterTriggerContent>
        </FilterTrigger>
        <Grid
          gridColumn={["3/26", null, "3/23", "10/25"]}
          gridTemplateColumns={[
            "repeat(1, 1fr)",
            null,
            null,
            "repeat(15, 1fr)",
          ]}
          style={{
            overflow: "hidden",
            opacity: showFilters ? 1 : 0,
            maxHeight: showFilters ? maxHeight : 0,
            transition: "0.26s all ease",
          }}
          ref={animateRef}
        >
          <Flex
            flexDirection={["column", null, "row", null]}
            mt={["48px", null, null, "nil"]}
            gridColumn={["1/1", null, null, "1/13"]}
            flexWrap={["no-wrap", null, "wrap", null]}
          >
            {distinctCategories.map((category) => (
              <LinkContainerBlock key={category}>
                <Link
                  href={`/category?category=${encodeURIComponent(category)}`}
                  mb={["24px", null, null, "4px"]}
                  customStyles={{
                    fontWeight: 500,
                    fontSize: "14px",
                    alignItems: "baseline",
                    "@media (min-width: 64rem)": {
                      fontSize: "12px",
                      lineHeight: "21px",
                    },
                  }}
                >
                  {category}
                </Link>
              </LinkContainerBlock>
            ))}
          </Flex>
          <Link
            href={`/category?category=${encodeURIComponent(
              "Explore All Categories"
            )}`}
            mt={["24px", null, null, "nil"]}
            gridColumn={["1/1", null, null, "13/16"]}
            customStyles={{
              fontSize: "14px",
              fontWeight: 500,
              height: "fit-content",
              width: "fit-content",
              "@media (min-width: 64rem)": {
                fontSize: "12px",
                alignItems: "baseline",
                lineHeight: "21px",
                marginLeft: "auto",
              },
            }}
          >
            <CenterFlex>
              Explore All Categories <IconArrowBoldRight />
            </CenterFlex>
          </Link>
        </Grid>
      </Grid>
    </>
  )
}
